import React from 'react';
import { HeadingOne } from '@marty-js/design/src/atoms/h1';
import styled from 'styled-components';
import { NavLink } from '@marty-js/sdk/src/atoms/nav-link';
import Head from 'next/head';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { useLayoutDataByUrlQuery } from '@marty-js/api-sdk/services/layoutData';
import { FullSizeContent } from '../modules/layout/FullSizeContent';
import { DefaultLayout } from '../modules/layout/default-layout';

const StyledLink = styled(NavLink)`
  margin-left: 4px;
  text-decoration: none;
  color: var(--theme-color-primary);
  font-weight: bold;
`;

const Paragraph = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const MainTitleRed = styled(HeadingOne)`
  color: var(--theme-color-primary);
  font-size: 55px;
  margin-bottom: 14px;
`;

export default function Custom500() {
  const t = useTranslation();

  const { layoutData } = useLayoutDataByUrlQuery({
    variables: { url: '/' },
    pollInterval: 60000,
  });

  return (
    <DefaultLayout data={layoutData?.data ? JSON.parse(layoutData.data) : null}>
      <Head>
        <title>{t('errorPage.500.metaSeo.title')}</title>
      </Head>
      <FullSizeContent>
        <MainTitleRed>{t('errorPage.500.title')}</MainTitleRed>
        <h2>{t('errorPage.500.subTitle')}</h2>
        <Paragraph>{t('errorPage.500.text')}</Paragraph>
        <Paragraph>
          <StyledLink href="/">{t('errorPage.500.backLink')}</StyledLink>
        </Paragraph>
      </FullSizeContent>
    </DefaultLayout>
  );
}

export const getStaticProps = async () => {
  return {
    revalidate: 60,
    props: {},
  };
};
